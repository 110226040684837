var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"material-list-table"},[_c('div',{staticClass:"material-list-table__table background-grey-light"},[_c('Container',{attrs:{"tag":"div"}},[_c('Margins',[_c('div',{staticClass:"grid-table"},[_c('div',{staticClass:"grid-table__row grid-table__row--offered-product grid-table__row--header"},[_c('div',{staticClass:"grid-table__column"}),_c('div',{staticClass:"grid-table__column"}),_c('div',{staticClass:"grid-table__column"},[_vm._v(" "+_vm._s(_vm.$t('OFFER.CALCULATED_QUANTITY'))+" ")]),_c('div',{staticClass:"grid-table__column grid-table__column--center text-primary"},[_vm._v(" "+_vm._s(_vm.$t('OFFER.CHOSEN_QUANTITY'))+" ")]),_c('div',{staticClass:"grid-table__column"},[_vm._v(_vm._s(_vm.$t('OFFER.PU')))]),_c('div',{staticClass:"grid-table__column"},[_vm._v(" "+_vm._s(_vm.$t('OFFER.No_PU'))+" ")]),_c('div',{staticClass:"grid-table__column grid-table__column--right"},[_vm._v(" "+_vm._s(_vm.$t('OFFER.PACKAGE_PRICE'))+" ")]),_c('div',{staticClass:"grid-table__column grid-table__column--right"},[_vm._v(" "+_vm._s(_vm.$t('OFFER.TOTAL_AMOUNT'))+" ")])])]),_vm._l((_vm.productsPerCategory[_vm.category]
            .offeredProducts),function(offeredProduct,index){return _c('OfferedProduct',{key:(_vm.category + "-" + index),attrs:{"offeredProduct":offeredProduct,"offeredProductIndex":index,"category":_vm.category}})})],2)],1)],1),_c('MaterialListAccessories',{attrs:{"accessories":_vm.accessories,"category":_vm.category}}),_c('div',{staticClass:"material-list-table__footer background-grey-light"},[_c('Container',{attrs:{"tag":"div"}},[_c('PriceSection',{attrs:{"prices":[
          {
            price: _vm.productsPerCategory[_vm.category].categoryTotalPrice,
            text: _vm.$t(("OFFER." + _vm.category + ".TOTAL_COMPONENTS")),
          },
          {
            price:
              _vm.productsPerCategory[_vm.category].categoryAccessoriesTotalPrice,
            text: _vm.$t(("OFFER." + _vm.category + ".TOTAL_ACCESSORIES")),
          } ]}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }