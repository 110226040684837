<template>
  <Card padding="none" shadow="medium">
    <div class="grid-table">
      <div class="grid-table__row grid-table__row--accessories">
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.SELECT_PRODUCT') }}:
          </div>
          <Check
            :name="`${accessory.artNo}_${
              accessory.parentProduct.availableProducts[
                accessory.parentProduct.selectedProductIndex
              ].artNo
            }`"
            :value="isChecked"
            @change="toggleChecked"
          />
        </div>
        <div class="grid-table__column">
          <a
            :href="`${BASE_WEBSTORE_PRODUCT_URL}/${accessory.artNo}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure class="product-image">
              <img
                v-if="accessory.imgUrl"
                :src="accessory.imgUrl"
                @error="() => (accessory.imgUrl = null)"
                :alt="accessory.artNo"
                class="product-image__image"
              />
              <PhotoSVG v-else class="icon icon--large product-image__image" />
            </figure>
          </a>
        </div>
        <div class="grid-table__column">
          <div class="offered-accessory__title">
            <a
              :href="`${BASE_WEBSTORE_PRODUCT_URL}/${accessory.artNo}`"
              target="_blank"
              rel="noopener noreferrer"
              class="text-strong text-primary"
            >
              <h6 class="text-uppercase">
                {{ $mt(accessory.name) }}
              </h6>
            </a>
            <StockLevelStatus
              v-if="$i18n.locale === 'sv'"
              :status="stockLevelStatus"
            ></StockLevelStatus>
          </div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.CALCULATED_QUANTITY') }}:
          </div>
          <div>{{ accessory.quantity }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.CHOSEN_QUANTITY') }}:
          </div>
          <ValidationProvider
            rules="numeric|min_value:0|required"
            v-slot="{ errors }"
            slim
          >
            <SpinButton
              :name="$mt(accessory.name)"
              :min="0"
              :max="10000"
              v-model="form.chosenQuantity"
              :error="errors[0]"
            />
          </ValidationProvider>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">{{ $t('OFFER.PU') }}:</div>
          <div>{{ accessory.unitsInPack }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">{{ $t('OFFER.No_PU') }}:</div>
          <div>{{ packsNeeded }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--right grid-table__column--border text-nowrap"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.PACKAGE_PRICE') }}:
          </div>
          <ProductPrice :product="accessory"></ProductPrice>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--right grid-table__column--border text-nowrap"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.TOTAL_AMOUNT') }}:
          </div>
          <strong>
            {{ totalPrice | formatPrice }}
          </strong>
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import { mapMutations } from 'vuex';
import { BASE_WEBSTORE_PRODUCT_URL } from '@/constants';
import Check from '@/components/Check';
import PhotoSVG from '@/assets/images/offer/camera-solid.svg?inline';
import SpinButton from '@/components/SpinButton';
import Card from '@/components/Card';
import StockLevelStatus from '@/components/StockLevelStatus.vue';
import { isInStock, isDiscounted } from '@/utils';
import ProductPrice from '@/components/offer/general/ProductPrice.vue';

export default {
  name: 'OfferedAccessory',
  components: {
    ProductPrice,
    Check,
    PhotoSVG,
    SpinButton,
    Card,
    StockLevelStatus,
  },
  props: {
    accessory: {
      type: Object,
      required: true,
    },
    accessoryIndex: {
      type: Number,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      form: {
        chosenQuantity: this.accessory.chosenQuantity ?? 0,
      },
      isChecked: !!this.accessory.active,
    };
  },
  computed: {
    BASE_WEBSTORE_PRODUCT_URL() {
      return BASE_WEBSTORE_PRODUCT_URL;
    },
    totalPrice() {
      return isDiscounted(this.accessory)
        ? this.packsNeeded * this.accessory.priceWithDiscount
        : this.packsNeeded * this.accessory.pricePerPack;
    },
    packsNeeded() {
      return Math.ceil(this.form.chosenQuantity / this.accessory.unitsInPack);
    },
    stockLevelStatus() {
      return isInStock(this.accessory, this.packsNeeded)
        ? 'in-stock'
        : 'online';
    },
  },
  watch: {
    'form.chosenQuantity': {
      handler(newQuantity) {
        this.isChecked = newQuantity > 0;
        this.setPrice();
      },
      immediate: true,
    },
  },
  methods: {
    ...mapMutations('offerStore', ['adjustAccessoryPrice']),
    toggleChecked() {
      this.isChecked = !this.isChecked;
      if (this.isChecked && this.form.chosenQuantity === 0) {
        this.form.chosenQuantity = 1;
      } else {
        this.form.chosenQuantity = 0;
      }
      this.setPrice();
    },
    setPrice() {
      this.adjustAccessoryPrice({
        active: this.isChecked,
        category: this.category,
        offeredProductIndex: this.accessory.parentProduct.index,
        selectedIndex: this.accessory.parentProduct.selectedProductIndex,
        accessoryIndex: this.accessoryIndex,
        chosenQuantity: this.form.chosenQuantity,
        newPrice: this.isChecked ? this.totalPrice : 0,
      });
    },
  },
};
</script>

<style lang="scss">
.offered-accessory__title {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  height: 100%;
}
</style>
