<template>
  <div class="material-list">
    <Hero>
      <h2>{{ $t('PAGE_TITLE') }} / {{ $t('OFFER.MATERIAL_LIST') }}</h2>
    </Hero>

    <ListInstruction :text="$t('OFFER.MATERIAL_INSTRUCTIONS')" />

    <Margins tag="section">
      <template v-for="category in Object.keys(productsPerCategory).sort()">
        <Margins
          v-if="hasOfferedProducts(category)"
          :key="category"
          class="margins__triple"
        >
          <Container tag="div">
            <h3 class="h4">{{ $t('OFFER.THIS_IS_WHAT_YOU_NEED') }}</h3>
          </Container>

          <MaterialListTable :category="category" />
        </Margins>
      </template>
    </Margins>

    <div class="background-grey-light">
      <Container>
        <Separator size="small" hasLine />

        <PriceSection
          :prices="[{ price: totalPrice, text: $t('OFFER.FULL_TOTAL_AMOUNT') }]"
          size="big"
        />

        <MaterialListFooter
          class="margins__double"
          @reset="resetInstance"
          @downloadPdf="downloadPdf"
        />
      </Container>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import ListInstruction from '@/components/offer/general/ListInstruction';
import PriceSection from '@/components/offer/general/PriceSection';
import MaterialListFooter from '@/components/offer/material-list/MaterialListFooter';
import Margins from '@/components/Margins';
import Hero from '@/components/Hero';
import Container from '@/components/Container';
import Separator from '@/components/Separator';
import MaterialListTable from '@/components/offer/material-list/MaterialListTable';

export default {
  name: 'MaterialList',
  components: {
    MaterialListFooter,
    ListInstruction,
    PriceSection,
    Margins,
    Hero,
    Container,
    Separator,
    MaterialListTable,
  },
  methods: {
    ...mapMutations(['resetInstance']),
    ...mapActions(['downloadPdf']),
    hasOfferedProducts(category) {
      return this.productsPerCategory[category].offeredProducts.length > 0;
    },
  },
  computed: {
    ...mapState('offerStore', ['productsPerCategory', 'totalPrice']),
  },
};
</script>
