<template>
  <div
    :class="['stock-level-status', { 'stock-level-status--online': isOnline }]"
  >
    {{ $t(stockLevelStatus) }}
  </div>
</template>

<script>
export default {
  name: 'StockLevelStatus',
  props: {
    status: {
      type: String,
      validator(value) {
        return ['in-stock', 'online'].includes(value);
      },
      required: true,
    },
  },
  computed: {
    stockLevelStatus() {
      return this.status === 'in-stock'
        ? 'OFFER.STOCK_LEVEL_STATUS.IN_STOCK'
        : 'OFFER.STOCK_LEVEL_STATUS.ONLINE';
    },
    isOnline() {
      return this.status === 'online';
    },
  },
};
</script>

<style lang="scss">
.stock-level-status {
  font-size: 0.75rem;
  border: 1px solid;
  border-radius: 1rem;
  padding: 0.125rem 0.5rem;
  border-color: $color-grey-dark;
}

.stock-level-status--online {
  border-color: $color-highlight;
  color: $color-highlight;
}
</style>
