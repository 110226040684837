<template>
  <div class="material-list-accessories background-white">
    <Container tag="div">
      <Margins>
        <h4>
          {{ $t('OFFER.YOU_NEED_ACCESSORIES') }}
        </h4>

        <div class="grid-table margins__double">
          <div
            class="grid-table__row grid-table__row--accessories grid-table__row--header"
          >
            <div class="grid-table__column"></div>
            <div class="grid-table__column"></div>
            <div class="grid-table__column"></div>
            <div class="grid-table__column">
              {{ $t('OFFER.CALCULATED_QUANTITY') }}
            </div>
            <div
              class="grid-table__column grid-table__column--center text-primary"
            >
              <strong>{{ $t('OFFER.CHOSEN_QUANTITY') }}</strong>
            </div>
            <div class="grid-table__column">{{ $t('OFFER.PU') }}</div>
            <div class="grid-table__column">{{ $t('OFFER.No_PU') }}</div>
            <div class="grid-table__column grid-table__column--right">
              {{ $t('OFFER.PACKAGE_PRICE') }}
            </div>
            <div class="grid-table__column grid-table__column--right">
              {{ $t('OFFER.TOTAL_AMOUNT') }}
            </div>
          </div>
        </div>

        <OfferedAccessory
          v-for="(accessory, index) in accessories"
          :key="index"
          :accessoryIndex="accessory.upsaleProductIndex"
          :accessory="accessory"
          :category="category"
        />
      </Margins>
    </Container>
  </div>
</template>

<script>
import Margins from '@/components/Margins';
import OfferedAccessory from './OfferedAccessory';
import Container from '@/components/Container';

export default {
  name: 'MaterialListAccessories',
  components: {
    Margins,
    OfferedAccessory,
    Container,
  },
  props: {
    accessories: {
      type: Array,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.material-list-accessories {
  padding-top: 2rem;
  padding-bottom: 2rem;
}
</style>
