<template>
  <div class="material-list-footer">
    <Button @click="showModal" variant="inverse">
      <template v-slot:icon-left>
        <UndoSVG class="icon" />
      </template>
      {{ $t('OFFER.CALCULATE_NEW') }}
    </Button>

    <b-modal id="confirm" size="md" centered hide-header hide-footer>
      <div class="material-list-footer__modal-header">
        <h5>{{ $t('MODAL.OFFER_RESET_CONFIRMATION') }}</h5>
      </div>
      <div
        class="material-list-footer__modal-buttons text-center margins__double"
      >
        <Button variant="ghost" @click="closeModal()">
          {{ $t('MODAL.CANCEL') }}
        </Button>
        <Button @click="backToStart()">
          {{ $t('MODAL.CONFIRM') }}
        </Button>
      </div>
    </b-modal>

    <div class="material-list-footer__more-buttons">
      <Button to="/" variant="inverse">
        <template v-slot:icon-left>
          <ArrowLeftSVG class="icon button__icon-animate" />
        </template>
        {{ $t('OFFER.BACK_TO_THE_CALCULATOR') }}
      </Button>

      <Button
        variant="inverse"
        @click="delegateDownload"
        :loading="isDownloading"
        class="material-list-footer__download-button"
      >
        <template v-slot:icon-left>
          <PdfIconSVG class="icon" />
        </template>
        {{ $t('OFFER.DOWNLOAD_PDF') }}
      </Button>

      <Button to="/offer/purchase-list">
        {{ $t('OFFER.GO_TO_PURCHASE') }}
        <template v-slot:icon-right>
          <ArrowRightSVG class="icon button__icon-animate" />
        </template>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';
import PdfIconSVG from '@/assets/images/offer/pdf-icon.svg?inline';
import ArrowRightSVG from '@/assets/images/icon-long-arrow-alt-right-solid.svg?inline';
import ArrowLeftSVG from '@/assets/images/icon-long-arrow-alt-left-solid.svg?inline';
import UndoSVG from '@/assets/images/icon-undo-alt-solid.svg?inline';
import { mapState } from 'vuex';

export default {
  name: 'MaterialListFooter',
  components: {
    Button,
    PdfIconSVG,
    ArrowRightSVG,
    ArrowLeftSVG,
    UndoSVG,
  },
  methods: {
    backToStart() {
      this.$router.push('/');
      this.$emit('reset');
    },
    delegateDownload() {
      this.$emit('downloadPdf');
    },
    showModal() {
      this.$bvModal.show('confirm');
    },
    closeModal() {
      this.$bvModal.hide('confirm');
    },
  },
  computed: {
    ...mapState(['isDownloading']),
  },
};
</script>

<style lang="scss">
.material-list-footer {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;

  @include min-width(md) {
    grid-template-columns: auto;
  }

  @include min-width(lg) {
    justify-content: space-between;
    grid-template-columns: auto auto;
    transform: translateY(1.5rem);
  }
}

.material-list-footer__more-buttons {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  justify-content: flex-end;

  @include min-width(lg) {
    grid-template-columns: repeat(3, auto);
  }
}

.material-list-footer__download-button {
  order: -1;

  @include min-width(lg) {
    order: 0;
  }
}

.material-list-footer__modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-grey-light;
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.material-list-footer__modal-buttons {
  > * + * {
    margin-left: 1rem;
  }
}
</style>
