<template>
  <div
    :class="[
      'product-price',
      {
        'product-price--align-right': align === 'right',
      },
    ]"
  >
    <template v-if="isDiscounted">
      <div class="product-price__old-price">
        {{ product.pricePerPack | formatPrice }}
        <template v-if="showCurrency">{{ $t('OFFER.CURRENCY') }}</template>
      </div>
      <div class="product-price__container">
        <Button
          v-if="product.discountLabel"
          variant="link"
          v-b-tooltip.hover.focus
          :title="product.discountLabel"
        >
          <InfoSVG class="product-price__info-icon"></InfoSVG>
        </Button>
        <div>
          {{ product.priceWithDiscount | formatPrice }}
          <template v-if="showCurrency">{{ $t('OFFER.CURRENCY') }}</template>
        </div>
      </div>
    </template>
    <template v-else>
      {{ product.pricePerPack | formatPrice }}
      <template v-if="showCurrency">{{ $t('OFFER.CURRENCY') }}</template>
    </template>
  </div>
</template>

<script>
import Button from '@/components/Button.vue';
import InfoSVG from '@/assets/images/icon-info.svg?inline';
import { isDiscounted } from '@/utils';

export default {
  name: 'ProductPrice',
  components: { Button, InfoSVG },
  props: {
    product: {
      type: Object,
      required: true,
    },
    align: {
      validator(value) {
        return ['left', 'right'].includes(value);
      },
      default: 'right',
    },
    showCurrency: {
      type: Boolean,
    },
  },
  computed: {
    isDiscounted() {
      return isDiscounted(this.product);
    },
  },
};
</script>

<style lang="scss">
.product-price--align-right {
  text-align: right;
}

.product-price__old-price {
  text-decoration: line-through;
  font-size: $font-size-small;
  color: $color-grey-dark;
}

.product-price__info-icon {
  height: 2em;
  width: 2em;
  fill: currentColor;
  color: $color-info;
}

.product-price__container {
  display: flex;
  align-items: center;
  flex-wrap: wrap-reverse;

  .product-price--align-right & {
    justify-content: flex-end;
  }
}
</style>
