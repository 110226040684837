<template>
  <Container tag="div" class="list-instruction">
    <p class="list-instruction__content">
      {{ text }}
    </p>
  </Container>
</template>

<script>
import Container from '@/components/Container';

export default {
  name: 'ListInstructions',
  components: {
    Container,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.list-instruction {
  margin-top: -2.65rem;
}

.list-instruction__content {
  background: $color-white;
  padding: 1rem;
}
</style>
