<template>
  <div class="offered-product-modal-content">
    <div class="offered-product-modal-content__header">
      <h5>{{ $t('OFFER.INFO_AND_OPTIONS') }}</h5>
      <Button variant="link" @click="hideModal" :aria-label="$t('CLOSE')">
        <CloseSVG class="icon" />
      </Button>
    </div>
    <Margins>
      <div
        v-if="availableProducts.length > 1"
        class="offered-product-modal-content__options"
      >
        <OfferRadio
          v-for="option in selectOptions"
          :key="option.value"
          name="availableProducts"
          :label="option.label"
          :value="option.value"
          :selectedValue="selectedProductIndex"
          :id="`availableProducts-${option.value}`"
          @input="selectedProductIndex = option.value"
          :image="option.image"
        />
      </div>
      <div v-else class="offered-product-modal-content__options">
        <OfferRadio
          name="availableProducts"
          :label="$mt(selectedProduct.name)"
          :image="selectedProduct.imgUrl"
          readOnly
        />
      </div>
      <p class="margins__one">
        {{ $mt(selectedProduct.description) }}
      </p>
      <div
        v-if="availableProducts.length > 1"
        class="text-strong offered-product-modal-content__footer"
      >
        <ProductPrice :product="selectedProduct" show-currency></ProductPrice>
      </div>

      <div
        class="offered-product-modal-content__buttons text-center margins__double"
      >
        <Button @click="hideModal" variant="ghost">
          {{ $t('CANCEL') }}
        </Button>
        <Button @click="selectProduct">
          {{ $t('OFFER.CHANGE_PRODUCT') }}
        </Button>
      </div>
    </Margins>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import CloseSVG from '@/assets/images/icon-close.svg?inline';
import Margins from '@/components/Margins.vue';
import Button from '@/components/Button';
import OfferRadio from './OfferRadio';
import ProductPrice from '@/components/offer/general/ProductPrice.vue';

export default {
  name: 'OfferedProductModalContent',
  components: {
    ProductPrice,
    CloseSVG,
    Margins,
    Button,
    OfferRadio,
  },
  props: {
    offeredProduct: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    offeredProductIndex: {
      type: Number,
      required: true,
    },
    hideModal: Function,
  },
  data() {
    return {
      selectedProductIndex: this.offeredProduct.selectedProductIndex,
      availableProducts: this.offeredProduct.availableProducts,
    };
  },
  methods: {
    ...mapMutations('offerStore', ['setSelectedProduct']),
    selectProduct() {
      const selectedIndex = Number(this.selectedProductIndex);
      this.setSelectedProduct({
        category: this.category,
        offeredProductIndex: this.offeredProductIndex,
        selectedIndex,
      });
      this.$emit('setSelectedIndex', selectedIndex);
      this.$emit('setPrice');
      this.hideModal();
    },
  },
  computed: {
    selectOptions() {
      return this.availableProducts.map((product, index) => ({
        value: index,
        label: this.$mt(product.name),
        image: product.imgUrl,
      }));
    },
    selectedProduct() {
      return this.offeredProduct.availableProducts[this.selectedProductIndex];
    },
  },
};
</script>

<style lang="scss">
.offered-product-modal-content__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $color-grey-light;
  margin-top: -1rem;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 0.5rem 0.5rem 0.5rem 1rem;
}

.offered-product-modal-content__options {
  margin-left: -1rem;
  margin-right: -1rem;
}

.offered-product-modal-content__footer {
  border-top: 1px solid $color-grey-light;
  padding-top: 1rem;
}

.offered-product-modal-content__buttons {
  > * + * {
    margin-left: 1rem;
  }
}
</style>
