<template>
  <Margins class="price-section">
    <template v-for="(item, index) in prices">
      <component
        :is="size === 'big' ? 'h4' : 'h5'"
        :key="index"
        class="price-section__content text-right"
      >
        <span :class="{ 'text-normal': size !== 'big' }"> {{ item.text }}</span>
        <span class="text-nowrap">
          {{ item.price | formatPrice }}
          {{ $t('OFFER.CURRENCY') }}
        </span>
      </component>
    </template>
    <Info v-if="!!infoProps" v-bind="infoProps" variant="warning" />
  </Margins>
</template>

<script>
import Margins from '@/components/Margins';
import Info from '@/components/Info';

export default {
  name: 'PriceSection',
  components: { Margins, Info },
  props: {
    prices: {
      type: Array,
      required: true,
    },
    size: {
      type: String,
      validator: function (value) {
        return ['big'].includes(value);
      },
    },
    infoProps: {
      type: Object,
    },
  },
};
</script>

<style lang="scss">
.price-section__content {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 1rem;

  @include min-width(sm) {
    grid-template-columns: 1fr minmax(8rem, 10%);
    gap: 2rem;
  }
}
</style>
