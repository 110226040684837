<template>
  <div class="material-list-table">
    <div class="material-list-table__table background-grey-light">
      <Container tag="div">
        <Margins>
          <div class="grid-table">
            <div
              class="grid-table__row grid-table__row--offered-product grid-table__row--header"
            >
              <div class="grid-table__column"></div>
              <div class="grid-table__column"></div>
              <div class="grid-table__column">
                {{ $t('OFFER.CALCULATED_QUANTITY') }}
              </div>
              <div
                class="grid-table__column grid-table__column--center text-primary"
              >
                {{ $t('OFFER.CHOSEN_QUANTITY') }}
              </div>
              <div class="grid-table__column">{{ $t('OFFER.PU') }}</div>
              <div class="grid-table__column">
                {{ $t('OFFER.No_PU') }}
              </div>
              <div class="grid-table__column grid-table__column--right">
                {{ $t('OFFER.PACKAGE_PRICE') }}
              </div>
              <div class="grid-table__column grid-table__column--right">
                {{ $t('OFFER.TOTAL_AMOUNT') }}
              </div>
            </div>
          </div>

          <OfferedProduct
            v-for="(offeredProduct, index) in productsPerCategory[category]
              .offeredProducts"
            :key="`${category}-${index}`"
            :offeredProduct="offeredProduct"
            :offeredProductIndex="index"
            :category="category"
          />
        </Margins>
      </Container>
    </div>

    <MaterialListAccessories :accessories="accessories" :category="category" />

    <div class="material-list-table__footer background-grey-light">
      <Container tag="div">
        <PriceSection
          :prices="[
            {
              price: productsPerCategory[category].categoryTotalPrice,
              text: $t(`OFFER.${category}.TOTAL_COMPONENTS`),
            },
            {
              price:
                productsPerCategory[category].categoryAccessoriesTotalPrice,
              text: $t(`OFFER.${category}.TOTAL_ACCESSORIES`),
            },
          ]"
        />
      </Container>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import OfferedProduct from '@/components/offer/material-list/OfferedProduct';
import PriceSection from '@/components/offer/general/PriceSection';
import Margins from '@/components/Margins';
import Container from '@/components/Container';
import MaterialListAccessories from './MaterialListAccessories';

export default {
  name: 'MaterialListTable',
  components: {
    OfferedProduct,
    PriceSection,
    Margins,
    MaterialListAccessories,
    Container,
  },
  props: {
    category: {
      type: String,
      required: true,
    },
  },
  methods: {
    hasOfferedProducts(category) {
      return this.productsPerCategory[category].offeredProducts.length > 0;
    },
  },
  //TODO: Right now we filter duplicate products in front end. Maybe better to update logic on backend.
  computed: {
    ...mapState('offerStore', ['productsPerCategory']),
    accessories() {
      return [
        ...new Map(
          this.productsPerCategory[this.category]?.offeredProducts
            .flatMap((offeredProduct, offeredProductIndex) =>
              offeredProduct.availableProducts[
                offeredProduct.selectedProductIndex
              ].upsaleProducts.map((upsaleProduct, index) => ({
                ...upsaleProduct,
                upsaleProductIndex: index,
                parentProduct: {
                  ...offeredProduct,
                  index: offeredProductIndex,
                },
              })),
            )
            .map((product) => [product['artNo'], product]),
        ).values(),
      ];
    },
  },
};
</script>

<style lang="scss">
.material-list-table__table {
  padding-top: 1rem;
  padding-bottom: 2rem;
}

.material-list-table__footer {
  padding-top: 2rem;
}
</style>
