<template>
  <Card padding="none" shadow="medium">
    <div class="grid-table">
      <div class="grid-table__row grid-table__row--offered-product">
        <div class="grid-table__column">
          <a
            :href="`${BASE_WEBSTORE_PRODUCT_URL}/${selectedProduct.artNo}`"
            target="_blank"
            rel="noopener noreferrer"
          >
            <figure class="product-image">
              <img
                v-if="selectedProduct.imgUrl"
                :src="selectedProduct.imgUrl"
                @error="() => (selectedProduct.imgUrl = null)"
                :alt="selectedProduct.artNo"
                class="product-image__image"
              />
              <PhotoSVG v-else class="icon icon--large product-image__image" />
            </figure>
          </a>
        </div>
        <div class="grid-table__column">
          <div class="offered-product__title">
            <a
              :href="`${BASE_WEBSTORE_PRODUCT_URL}/${selectedProduct.artNo}`"
              target="_blank"
              rel="noopener noreferrer"
              class="text-black"
            >
              <h6 class="text-uppercase">
                {{ $mt(selectedProduct.name) }}
              </h6>
            </a>
            <StockLevelStatus
              v-if="$i18n.locale === 'sv'"
              :status="stockLevelStatus"
            ></StockLevelStatus>
            <Button @click="showModal" variant="link">
              {{ $t('OFFER.SHOW_INFO') }}
              <template v-if="availableProducts.length > 1"
                >{{ $t('OFFER.SHOW_INFO_OPTIONS', [availableProducts.length]) }}
              </template>
            </Button>
          </div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.CALCULATED_QUANTITY') }}:
          </div>
          <div>{{ offeredProduct.quantity }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.CHOSEN_QUANTITY') }}:
          </div>
          <ValidationProvider
            rules="numeric|min_value:0|required"
            v-slot="{ errors }"
            slim
          >
            <SpinButton
              :name="$mt(selectedProduct.name)"
              :min="0"
              :max="10000"
              v-model="form.chosenQuantity"
              @change="setPrice"
              :error="errors[0]"
            />
          </ValidationProvider>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">{{ $t('OFFER.PU') }}:</div>
          <div>{{ selectedProduct.unitsInPack }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--center grid-table__column--border"
        >
          <div class="grid-table__mobile-header">{{ $t('OFFER.No_PU') }}:</div>
          <div>{{ packsNeeded }}</div>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--right grid-table__column--border text-nowrap"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.PACKAGE_PRICE') }}:
          </div>
          <ProductPrice :product="selectedProduct"></ProductPrice>
        </div>
        <div
          class="grid-table__column grid-table__column--has-mobile-header grid-table__column--right grid-table__column--border text-nowrap"
        >
          <div class="grid-table__mobile-header">
            {{ $t('OFFER.TOTAL_AMOUNT') }}:
          </div>
          <strong>
            {{ totalPrice | formatPrice }}
          </strong>
        </div>
      </div>
    </div>

    <b-modal ref="modalRef" size="md" centered hide-footer hide-header>
      <OfferedProductModalContent
        :offeredProduct="offeredProduct"
        :category="category"
        :offeredProductIndex="offeredProductIndex"
        :hideModal="hideModal"
        @setPrice="setPrice"
        @setSelectedIndex="setSelectedIndex"
      />
    </b-modal>
  </Card>
</template>

<script>
import { mapMutations } from 'vuex';
import PhotoSVG from '@/assets/images/offer/camera-solid.svg?inline';
import { BASE_WEBSTORE_PRODUCT_URL } from '@/constants';
import SpinButton from '@/components/SpinButton';
import Card from '@/components/Card';
import Button from '@/components/Button';
import OfferedProductModalContent from './OfferedProductModalContent';
import StockLevelStatus from '@/components/StockLevelStatus.vue';
import ProductPrice from '@/components/offer/general/ProductPrice.vue';
import { isInStock, isDiscounted } from '@/utils';

export default {
  name: 'OfferedProduct',
  components: {
    ProductPrice,
    SpinButton,
    PhotoSVG,
    Card,
    Button,
    OfferedProductModalContent,
    StockLevelStatus,
  },
  props: {
    offeredProduct: {
      type: Object,
      required: true,
    },
    category: {
      type: String,
      required: true,
    },
    offeredProductIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      form: {
        chosenQuantity: this.offeredProduct.chosenQuantity,
        selectedIndex: this.offeredProduct.selectedProductIndex,
      },
      availableProducts: this.offeredProduct.availableProducts,
    };
  },
  methods: {
    ...mapMutations('offerStore', ['adjustPrice', 'setSelectedProduct']),
    setPrice() {
      this.adjustPrice({
        category: this.category,
        offeredProductIndex: this.offeredProductIndex,
        chosenQuantity: this.form.chosenQuantity,
        newPrice: this.totalPrice,
      });
    },
    setSelectedIndex(index) {
      this.form.selectedIndex = index;
    },
    showModal() {
      this.$refs.modalRef.show();
    },
    hideModal() {
      this.$refs.modalRef.hide();
    },
  },
  computed: {
    BASE_WEBSTORE_PRODUCT_URL() {
      return BASE_WEBSTORE_PRODUCT_URL;
    },
    selectedProduct() {
      return this.availableProducts[this.form.selectedIndex];
    },
    packsNeeded() {
      return Math.ceil(
        this.form.chosenQuantity / this.selectedProduct.unitsInPack,
      );
    },
    totalPrice() {
      return isDiscounted(this.selectedProduct)
        ? this.packsNeeded * this.selectedProduct.priceWithDiscount
        : this.packsNeeded * this.selectedProduct.pricePerPack;
    },
    stockLevelStatus() {
      return isInStock(this.selectedProduct, this.packsNeeded)
        ? 'in-stock'
        : 'online';
    },
  },
  mounted() {
    //:TODO price calculated on the backend and on the frontend. Therefore forced price recalculation at component mount needed. This part can be refactored in future.
    this.setPrice();
  },
};
</script>

<style lang="scss">
.offered-product__title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}
</style>
