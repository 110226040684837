<template>
  <div
    :class="[
      'offer-radio',
      {
        'is-read-only': readOnly,
      },
    ]"
  >
    <input
      type="radio"
      class="offer-radio__input"
      :id="id || name"
      :name="name"
      :checked="value === selectedValue"
      :value="value"
      :aria-invalid="error"
      :aria-describedby="`error-${name}`"
      @input="updateInput"
      @change="changeInput"
    />
    <label :for="id || name" class="offer-radio__label">
      <figure class="offer-radio__product-figure">
        <img
          v-if="image"
          :src="image"
          @error="() => (image = null)"
          :alt="label"
          class="offer-radio__product-image"
        />
        <PhotoSVG v-else class="icon icon--large product-image__image" />
      </figure>
      <span class="offer-radio__label-text" v-html="label" />
      <span class="offer-radio__indicator" v-if="!readOnly" />
    </label>
    <p v-if="error" :id="`error-${name}`" class="offer-radio__error">
      {{ error }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'OfferRadio',
  props: {
    label: String,
    name: {
      type: String,
      required: true,
    },
    id: String,
    value: [String, Number],
    selectedValue: [String, Number],
    error: String,
    image: String,
    readOnly: Boolean,
  },
  methods: {
    updateInput(e) {
      this.$emit('input', e.target.checked || undefined);
    },
    changeInput(e) {
      this.$emit('change', e.target.checked || undefined);
    },
  },
};
</script>

<style lang="scss">
.offer-radio {
  position: relative;
}

.offer-radio__input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.offer-radio__label {
  display: flex;
  border-bottom: 1px solid $color-grey-light;
  cursor: pointer;

  .offer-radio.is-read-only & {
    cursor: default;
  }
}

.offer-radio__product-figure {
  position: relative;
  margin: 0.25rem;
  flex-shrink: 0;

  &::after {
    content: '';
    position: absolute;
    top: -0.25rem;
    bottom: -0.25rem;
    right: -0.25rem;
    width: 1px;
    background-color: $color-grey-light;
  }
}

.offer-radio__product-image {
  height: 2.5rem;
  width: 2.5rem;
  object-fit: cover;
}

.offer-radio__label-text {
  display: flex;
  align-items: center;
  flex-grow: 1;
  font-weight: $font-weight-bold;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  text-transform: uppercase;
}

.offer-radio__indicator {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-grey-light;
  padding: 0.25rem;

  &::before {
    content: '';
    display: block;
    border-radius: 99em;
    height: 2rem;
    width: 2rem;
    border: rem(2px) solid $color-grey-darkest;
  }
}

.offer-radio__input:checked + .offer-radio__label .offer-radio__indicator {
  background: $color-confirm;

  &::before {
    border-color: $color-primary;
  }

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 99em;
    height: 1rem;
    width: 1rem;
    background-color: $color-primary;
  }
}

.offer-radio__input.focus-visible
  + .offer-radio__label
  .offer-radio__indicator::before {
  border-color: $color-black;
}

.offer-radio__error {
  font-size: $font-size-small;
  color: $color-error;
  margin-top: 0.5rem;
}
</style>
